<template>
  <div>
    <AppBar
      :barTitle="barTitle"
      :user="user"
      :backButtonVisible="backButtonValidator"
      :isMobile="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdAndDown"
      @go-back="routeBack"
      @logout="logoutClickHandler"
      @toggle-drawer="drawer = !drawer"
    />
    <Sidebar
      :drawer.sync="drawer"
      :navLinks="navLinks"
      :selectedItem="selectedItem"
      :isMobile="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdAndDown"
      @tab-change="ChangeTab"
    />
    <v-main>
  <v-container fluid>
    <router-view></router-view>
  </v-container>
</v-main>

  </div>
</template>

<script>
import AppBar from "@/components/Dashboard/AppBar.vue"
import Sidebar from "@/components/Dashboard/SideBar.vue"
import { ROUTER_URL } from "@/constants/urls";
import authToken from "@/common/authToken";
import { SALES_REPRESENTATIVE } from "@/constants/ModuleKeys";
import { mapActions } from "vuex";

export default {
  name: "AdminLayout",
  components: {
    AppBar,
    Sidebar,
  },
  data() {
    return {
      subAdminAccess: localStorage.getItem("subadminAccess"),
      user: {},
      selectedItem: 0,
      navLinks: [
        {
          title: "Dashboard",
          icon: "dashboard",
          heading: "Internal Dashboard",
          key: "dashboard",
          to: ROUTER_URL.adminPanel.children.dashboard.name,
        },
        {
          title: "Wizfit Campaigns",
          icon: "campaign",
          heading: "Campaign Management",
          key: "campaignManagement",
          to: ROUTER_URL.adminPanel.children.campaignManagement.name,
        },
        {
          title: "Districts",
          icon: "district",
          heading: "District Management",
          key: "districtManagement",
          to:
            ROUTER_URL.adminPanel.children.districtManagement.name ||
            ROUTER_URL.adminPanel.children.bulkUpload.name,
        },
        {
          title: "Schools",
          icon: "school",
          heading: "School Management",
          key: "schoolManagement",
          to: ROUTER_URL.adminPanel.children.schoolManagement.name,
        },
        {
          title: "Teachers",
          icon: "teacher",
          heading: "Teacher Management",
          key: "teacherManagement",
          to: ROUTER_URL.adminPanel.children.teacherManagement.name,
        },
        {
          title: "Students",
          icon: "student",
          heading: "Student Management",
          key: "studentManagement",
          to: ROUTER_URL.adminPanel.children.studentManagement.name,
        },
        {
          title: "Donations",
          icon: "donation",
          heading: "Donation Management",
          key: "donationManagment",
          to: ROUTER_URL.adminPanel.children.donationManagement.name,
        },
        {
          title: "Emails",
          icon: "email",
          heading: "Email",
          key: "emails",
          to: ROUTER_URL.adminPanel.children.emailSettings.name,
        },
        {
          title: "Prizes",
          icon: "prize",
          heading: "Prizes",
          key: "prizeManagement",
          to: ROUTER_URL.adminPanel.children.prizeManagement.name,
        },
        {
          title: "Sponsors",
          icon: "sponsor",
          heading: "Sponsor Management",
          key: "sponsorManagement",
          to: ROUTER_URL.adminPanel.children.sponsorManagement.name,
        },
        {
          title: "Users",
          icon: "team",
          heading: "Users Management",
          key: "teamManagement",
          to: ROUTER_URL.adminPanel.children.teamManagement.name,
        },

        {
          title: "Games",
          icon: "game",
          heading: "Game Management",
          key: "gameManagement",
          to: ROUTER_URL.adminPanel.children.gameManagement.name,
        },
        {
          title: "Calendar",
          icon: "calendars",
          heading: "Calendar Management",
          key: "calendarManagement",
          to: ROUTER_URL.adminPanel.children.calendarManagement.name,
        },
        {
          title: "Resources",
          icon: "sponsor",
          heading: "Resources Management",
          key: "resourceManagement",
          to: ROUTER_URL.adminPanel.children.resourceManagement.name,
        },
        {
          title: "Non Game Event",
          icon: "campaign",
          heading: "Non Game Event Management",
          key: "nongameeventManagement",
          to: ROUTER_URL.adminPanel.children.nongameeventManagement.name,
        },
        {
          title: "Hotel",
          icon: "school",
          heading: "Hotel Management",
          key: "hotelManagement",
          to: ROUTER_URL.adminPanel.children.hotelManagement.name,
        },
        {
          title: "Assembly",
          icon: "school",
          heading: "Assembly Management",
          key: "assemblyManagement",
          to: ROUTER_URL.adminPanel.children.assemblyManagement.name,
        },
        //  {
        //  title: "Player Availability List",
        //  icon: "calendars",
        //  heading: "Player Availability Management",
        //   key: "playerListCalendar",
        //    to: ROUTER_URL.adminPanel.children.playerListCalendar.name,
        //  },

        //    {
        //     title: "Game Resources",
        //    icon: "sponsor",
        //    heading: "Game Resources Management",
        //   key: "gameresourceManagement",
        //    to: ROUTER_URL.adminPanel.children.gameresourceManagement.name,
        //  },
        //  {
        //    title: "Campaign Management",
        //    icon: "campaign",
        //    heading: "Onboarding Campaign Management",
        //   key: "onboardingManagement",
        //   to: ROUTER_URL.adminPanel.children.onboardingManagement.name,
        //  },

        // {
        //   title: "Reports",
        //   icon: "mdi-card-account-details",
        //   heading: "Reports",
        //   key: "reports",
        //   to: "reports",
        // },
        // {
        //   title: "Settings",
        //   icon: "mdi-cog",
        //   heading: "Settings",
        //   key: "settings",
        //   to: "settings",
        // },
      ],
      drawer: false,
      barTitle: "Campaign Management",
      routerCheck: true,
      prizeTab: [
        { title: "Prize Master", value: "prizeMaster" },
        { title: "Common Prizes", value: "commonPrizes" },
        { title: "Custom Prizes", value: "customPrizes" },
        {
          title: "Pending Prize Distribution",
          value: "pendingPrizeDistribution",
        },
        { title: "Distributed Prizes", value: "distributedPrizes" },
      ],
      prizeTabSelected: 0,
      emailTab: [
        { title: "Automated Email Template", value: "automatedEmailTemplate" },
        // { title: "Manual Email Template", value: "manualEmailTemplate" },
        { title: "Manual Emails", value: "bulkEmail" },
      ],
      emailSelected: 0,
    };
  },
  created() {
    this.$root.$refs.adminLayout = this;
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    backButtonValidator() {
      if (
        this.currentRouteName ===
          ROUTER_URL.adminPanel.children.newCampaign.name ||
        this.currentRouteName ===
          ROUTER_URL.adminPanel.children.bulkUploadSchool.name ||
        this.currentRouteName ===
          ROUTER_URL.adminPanel.children.bulkUploadDistrict.name
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    currentRouteName(val) {
      if (val === ROUTER_URL.adminPanel.children.newCampaign.name) {
        // this.selectedItem = this.navLinks.findIndex(
        //   (item) =>
        //     item.key === ROUTER_URL.adminPanel.children.campaignManagement.name
        // );
        if (this.$route.query.type == "add") {
          this.barTitle = "Add a new Campaign";
        } else {
          this.barTitle = "Edit Campaign";
        }
        this.selectedItem = this.navLinks.findIndex(
          (item) =>
            item.key === ROUTER_URL.adminPanel.children.campaignManagement.name
        );
      } else if (
        val === ROUTER_URL.adminPanel.children.bulkUploadDistrict.name
      ) {
        this.barTitle = "Upload District in bulk";
      } else if (val === ROUTER_URL.adminPanel.children.bulkUploadSchool.name) {
        this.barTitle = "Upload School in bulk";
      }
    },
    // selectedItem(val) {
    //   console.log(val, "SELECTED_ITEM");
    // },
  },
  methods: {
    ...mapActions({
      selectCampaignAction: "campaign/updateSelectedCampaign",
    }),
    routeBack() {
      const val = this.currentRouteName;
      this.routeReset(val, true);
      if (val === "bulkUploadDistrict") {
        this.$router.push({
          name: ROUTER_URL.adminPanel.children.districtManagement.name,
        });
        this.ChangeTab("District Management");
      } else if (val === "bulkUploadSchool") {
        this.$router.push({
          name: ROUTER_URL.adminPanel.children.schoolManagement.name,
        });
        this.ChangeTab("School Management");
      } else if (val === ROUTER_URL.adminPanel.children.newCampaign.name) {
        this.$router.push({
          name: ROUTER_URL.adminPanel.children.campaignManagement.name,
        });
      }
      this.routerCheck = false;
    },
    unmountReset(route) {
      if (this.routerCheck) {
        this.routeReset(route);
      } else {
        this.routerCheck = true;
      }
    },
    route(key) {
      if (key === "district") {
        let linkToBeChange =
          ROUTER_URL.adminPanel.children.districtManagement.name;
        let NewLink = ROUTER_URL.adminPanel.children.bulkUploadDistrict.name;
        let key = "bulkUploadDistrict";
        let heading = "Upload District in bulk";
        this.navLinksManipulation(linkToBeChange, NewLink, key, heading);
        this.$router.push({ name: NewLink });
        this.ChangeTab(heading);
      } else if (key === "school") {
        let linkToBeChange =
          ROUTER_URL.adminPanel.children.schoolManagement.name;
        let NewLink = ROUTER_URL.adminPanel.children.bulkUploadSchool.name;
        let key = "bulkUploadSchool";
        let heading = "Upload School in bulk";
        this.navLinksManipulation(linkToBeChange, NewLink, key, heading);
        this.$router.push({ name: NewLink });
        this.ChangeTab(heading);
      } else if (key === "campaign") {
        let linkToBeChange =
          ROUTER_URL.adminPanel.children.campaignManagement.name;
        let NewLink = ROUTER_URL.adminPanel.children.newCampaign.name;
        let key = "newCampaign";
        let heading = "Add a new campaign";
        this.navLinksManipulation(linkToBeChange, NewLink, key, heading);
        this.selectedItem = this.$router.push({
          name: NewLink,
          query: { type: "add" },
        });
        this.ChangeTab(heading);
      }
    },

    navLinksManipulation(linkToBeChange, NewLink, key, heading) {
      this.selectedItem = this.navLinks.findIndex(
        (item) => item.key === linkToBeChange
      );
      this.navLinks[this.selectedItem].to = NewLink;
      this.navLinks[this.selectedItem].key = key;
      this.navLinks[this.selectedItem].heading = heading;
    },

    routeReset(route) {
      if (route === ROUTER_URL.adminPanel.children.bulkUploadDistrict.name) {
        let linkToBeChange =
          ROUTER_URL.adminPanel.children.bulkUploadDistrict.name;
        let NewLink = ROUTER_URL.adminPanel.children.districtManagement.name;
        let key = "districtManagement";
        let heading = "District Management";
        this.navLinksManipulation(linkToBeChange, NewLink, key, heading);
      } else if (
        route === ROUTER_URL.adminPanel.children.bulkUploadSchool.name
      ) {
        let linkToBeChange =
          ROUTER_URL.adminPanel.children.bulkUploadSchool.name;
        let NewLink = ROUTER_URL.adminPanel.children.schoolManagement.name;
        let key = "schoolManagement";
        let heading = "School Management";
        this.navLinksManipulation(linkToBeChange, NewLink, key, heading);
      }
    },
    selectPrizeTab(tab) {
      this.$root.$refs.Prize.changeTab(tab);
    },
    resetPrizeTab() {
      this.prizeTabSelected = 0;
    },
    selectEmailTab(tab) {
      this.$root.$refs.Email.changeTab(tab);
    },
    routeBackEmailTab(tab) {
      this.emailSelected = this.emailTab.findIndex(
        (item) => item.value === tab
      );
      this.selectEmailTab(tab);
    },
    resetEmailTab() {
      this.emailSelected = 0;
    },
    getImgUrl(img) {
      return require("@/assets/Navigation/" + img);
    },
    /**
     * Logout button click handler
     */
    logoutClickHandler() {
      authToken.removeAccessToken();
      this.$router.push({ name: ROUTER_URL.auth.children.login.name });
      this.clearData();
    },
    /**
     * for changing the tabs and heading
     * param {string} title - navbar heading
     */
    ChangeTab(heading) {
      this.barTitle = heading;
      this.drawer = false;
    },
    /**
     * function returns user's initials
     * param {string}  user's full name
     **/
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    clearData() {
      this.selectCampaignAction({});
    },
    setupNavLinksForSalesREP() {
      let removeLinks = [
        "teamManagement",
        "donationManagment",
        "emails",
        "sponsorManagement",
        "prizeManagement",
      ];
      let newNavLinks = this.navLinks.filter(
        (nav) => !removeLinks.includes(nav.key)
      );
      this.navLinks = newNavLinks;
      console.log(this.navLinks);
    },
    setupNavLinksForEventSupport() {
      let removeLinks = [
        "teamManagement",
        "donationManagment",
        "emails",
        "sponsorManagement",
        "prizeManagement",
        "districtManagement",
        "schoolManagement",
        "teacherManagement",
        "studentManagement",
      ];
      let newNavLinks = this.navLinks.filter(
        (nav) => !removeLinks.includes(nav.key)
      );
      this.navLinks = newNavLinks;
      console.log(this.navLinks);
    },
    setupNavLinksForPlayer() {
      let removeLinks = [
        "teamManagement",
        "donationManagment",
        "emails",
        "sponsorManagement",
        "prizeManagement",
        "campaignManagement",
        "districtManagement",
        "schoolManagement",
        "teacherManagement",
        "studentManagement",
        "gameManagement",
        "resourceManagement",
        "gameresourceManagement",
        "nongameeventManagement",
        "hotelManagement",
        "assemblyManagement",
      ];
      let newNavLinks = this.navLinks.filter(
        (nav) => !removeLinks.includes(nav.key)
      );
      this.navLinks = newNavLinks;
      console.log(this.navLinks);
    },
    setupNavLinksForGame() {
      let removeLinks = [
        "teamManagement",
        "donationManagment",
        "emails",
        "sponsorManagement",
        "prizeManagement",
        "campaignManagement",
        "districtManagement",
        "schoolManagement",
        "teacherManagement",
        "studentManagement",
        "resourceManagement",
        "gameresourceManagement",
        "calendarManagement",
        "nongameeventManagement",
        "hotelManagement",
        "assemblyManagement",
      ];
      let newNavLinks = this.navLinks.filter(
        (nav) => !removeLinks.includes(nav.key)
      );
      this.navLinks = newNavLinks;
      console.log(this.navLinks);
    },
    setupNavLinksForGameAndSchool() {
      let removeLinks = [
        "teamManagement",
        "donationManagment",
        "emails",
        "sponsorManagement",
        "prizeManagement",
        "schoolManagement",
        "districtManagement",
        "teacherManagement",
        "studentManagement",
        "resourceManagement",
        "gameresourceManagement",
        "calendarManagement",
        "nongameeventManagement",
        "hotelManagement",
        "assemblyManagement",
      ];
      let newNavLinks = this.navLinks.filter(
        (nav) => !removeLinks.includes(nav.key)
      );
      this.navLinks = newNavLinks;
      console.log(this.navLinks);
    },
    setupNavLinksForGameAndDistrict() {
      let removeLinks = [
        "teamManagement",
        "donationManagment",
        "emails",
        "prizeManagement",
        "sponsorManagement",
        "schoolManagement",
        "districtManagement",
        "teacherManagement",
        "studentManagement",
        "resourceManagement",
        "gameresourceManagement",
        "calendarManagement",
        "nongameeventManagement",
        "hotelManagement",
        "assemblyManagement",
      ];
      let newNavLinks = this.navLinks.filter(
        (nav) => !removeLinks.includes(nav.key)
      );
      this.navLinks = newNavLinks;
      console.log(this.navLinks);
    },
  },
  mounted() {
    this.user = { ...authToken.decodedToken() };
    let initials = this.getInitials(this.user.user_name);
    this.user = { ...this.user, initials };
    if (this.user.user_type === "event support staff")
      this.setupNavLinksForEventSupport();
    if (this.user.user_type === SALES_REPRESENTATIVE)
      this.setupNavLinksForSalesREP();
    if (this.user.user_type === "player") this.setupNavLinksForPlayer();
    if (this.user.user_type === "game" && this.subAdminAccess == "school") {
      this.setupNavLinksForGameAndSchool();
    } else if (
      this.user.user_type === "game" &&
      this.subAdminAccess == "district"
    ) {
      this.setupNavLinksForGameAndDistrict();
    } else if (this.user.user_type === "game") {
      this.setupNavLinksForGame();
    }
    this.selectedItem = this.navLinks.findIndex(
      (item) => item.key === this.currentRouteName
    );
    // this.barTitle = this.navLinks[this.selectedItem].heading;
  },
};
</script>

<style scoped>
.mainToolbar-title {
  font-family: LubalGraph Bd BT;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 315%;
  color: #2d2d2d;
  white-space: nowrap;
}
.flex-zero {
  flex: none;
}
.display-inline {
  display: inline;
}
.font-roboto-slab {
  font-family: Roboto Slab !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 300% !important;
  color: #9387b6 !important;
}
.active-class {
  border-right: 6px solid #ffba00;
  width: 100%;
  border-radius: 0px !important;
}
.active-class >>> .v-list-item__title {
  color: #ffffff !important;
}
* >>> .theme--dark.v-list-item--active::before {
  opacity: 0.14;
  border-radius: 0px;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: #ff5151;
  letter-spacing: 0px;
}

.tabs {
  margin-left: 100px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .tabs {
    margin-left: 0px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
